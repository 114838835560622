<div class="header"></div>
<table>
  <tr class="heads">
    <th><div>#</div></th>
    <th><div>Activity Name</div></th>
    <th><div>Activity Type</div></th>

    <th>
      <div></div>
    </th>
  </tr>
  @for (activity of activities; track activity; let i = $index,last = $last) {

  <tr
    class="row"
    [style.border-bottom]="!last ? '1px solid #E8EBED9C' : 'none'"
  >
    <td>{{ i + 1 }}</td>

    <td>
      {{ activity.name }}
    </td>
    <td>
      {{ activityTypeData[activity.activityType] | translate }}
    </td>

    <td class="arrow" (click)="this.navigateToActivityAol(activity)">
      <figure><img src="assets/svgs/icons/right-arrow.svg" alt="" /></figure>
    </td>
  </tr>
  }
</table>
