import { Component, Input } from '@angular/core';
import { Activity } from '../../interfaces/aol-report-course-section.interface';
import { TranslateModule } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-activities',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './activities.component.html',
  styleUrl: './activities.component.scss',
})
export class ActivitiesComponent {
  @Input({ required: true }) activities!: Activity[];

  constructor(private readonly router: Router) {}

  activityTypeData = {
    1: 'activities.addActivity.activityType.firstExam',
    2: 'activities.addActivity.activityType.assignment',
    3: 'activities.addActivity.activityType.quiz',
    4: 'activities.addActivity.activityType.project',
    5: 'activities.addActivity.activityType.oralPresentation',
    6: 'activities.addActivity.activityType.writtenReport',
    7: 'activities.addActivity.activityType.caseStudy',
    8: 'activities.addActivity.activityType.secondExam',
    9: 'activities.addActivity.activityType.finalExam',
    10: 'activities.addActivity.activityType.midExam',
  };

  navigateToActivityAol(activity: Activity) {
    this.router.navigate([
      'activities',
      activity._id,
      'aol',
      'activity-report',
    ]);
  }
}
